<template>
  <section class="xa-container">
    <AppLineLoading v-if="isLoading" />
    <section ref="chart" class="xa-container"></section>
  </section>
</template>
<script>
import echarts from 'echarts'
import 'echarts/map/js/china.js'
import geoMapPingyin from '@/config/geoMapPingyin.json'
import { ResizeObserver } from '@juggle/resize-observer'
const throttle = function(action, delay = 300) {
  let handle = null
  return function() {
    clearTimeout(handle)
    handle = setTimeout(() => {
      action.apply(this, arguments)
    }, delay)
  }
}
export default {
  data() {
    return {
      isLoading: true,
      dataBase: {},
      unobserve: null
    }
  },
  methods: {
    sizeChange: throttle(function() {
      this.$refs.chart && this.$refs.chart.instance.resize()
    }, 1000 / 32),
    async init() {
      this.$refs.chart.instance = echarts.init(this.$refs.chart)
      this.$refs.chart.instance.showLoading({ text: '绘图中...' })
      const data = await this.$actionByRoute()
      const config = this.getChartOption(
        this.$route.query.title,
        this.initDatas(data)
      )
      this.$refs.chart.instance.setOption(config)
      this.$refs.chart.instance.hideLoading()
      this.isLoading = false
      this.$refs.chart.instance.on('click', avg => {
        const url = this.dataBase[avg.name]
        this.$gotoUrl(url)
      })
    },
    initDatas(datas) {
      let echartsData = []
      let keys = Object.keys(geoMapPingyin)
      for (var j = 0, len = keys.length; j < len; j++) {
        for (var i = 0, l = datas.length; i < l; i++) {
          const nav = datas[i]
          if (nav.title.indexOf(keys[j]) > -1) {
            this.dataBase[keys[j]] = nav.url
            echartsData.push({
              name: keys[j],
              itemStyle: { normal: { areaColor: '#FAFAD2' } }
            })
            break
          }
        }
      }
      return echartsData
    },
    getChartOption(title, data) {
      return {
        title: {
          text: title,
          y: 20,
          x: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}'
        },
        series: [
          {
            name: '中国',
            type: 'map',
            map: 'china',
            roam: true,
            label: {
              normal: {
                show: true
              },
              emphasis: {
                show: true
              }
            },
            data
          }
        ]
      }
    }
  },
  mounted() {
    var mResizeObserver = new ResizeObserver(entries =>
      this.sizeChange(entries)
    )
    mResizeObserver.observe(this.$el)
    this.unobserve = function() {
      mResizeObserver.unobserve
      mResizeObserver = null
    }
    this.init()
  },
  beforeDestroy() {
    this.$refs.chart.instance.dispose()
    this.unobserve()
  }
}
</script>
<style lang="scss" scoped></style>
